// @flow

import type { MessageDescriptor } from 'react-intl'

import type {
  ProductSortInput,
  OrderSortInput,
  OrderItemSortInput,
  ShipmentSortInput,
  BatchSortInput,
  ProductProviderSortInput,
  ContainerSortInput,
  FileSortInput,
  FolderSortInput,
  MaskEditSortInput,
  PartnerSortInput,
  TagSortInput,
  UserSortInput,
  WarehouseSortInput,
} from '@graphql/server/flow'
import { shipmentSortMessages as shipmentMessages } from '@modules/shipment/messages'

import batchMessages from 'modules/batch/messages'
import containerMessages from 'modules/container/messages'
import fileMessages from 'modules/document/messages'
import orderMessages from 'modules/order/messages'
import orderItemMessages from 'modules/orderItem/messages'
import partnerMessages from 'modules/partner/messages'
import productMessages from 'modules/product/messages'
import tableTemplateMessages from 'modules/tableTemplate/messages'
import tagMessages from 'modules/tags/messages'
import userMessages from 'modules/user/messages'
import warehouseMessages from 'modules/warehouse/messages'

import type { SortConfig } from './index'

type EntitySortConfig<T> = {
  message: MessageDescriptor,
  field: $Keys<T>,
}

export const ProductSortConfig: EntitySortConfig<ProductSortInput>[] = [
  { message: productMessages.updatedAt, field: 'updatedAt' },
  { message: productMessages.createdAt, field: 'createdAt' },
  { message: productMessages.name, field: 'name' },
  { message: productMessages.serial, field: 'serial' },
]

export const ProductProviderSortConfig: EntitySortConfig<ProductProviderSortInput>[] = [
  { message: orderMessages.updatedAt, field: 'updatedAt' },
  { message: orderMessages.createdAt, field: 'createdAt' },
  { message: orderMessages.endProductName, field: 'name' },
  { message: orderMessages.productName, field: 'productName' },
  { message: orderMessages.productSerial, field: 'productSerial' },
  { message: orderMessages.priceCurrency, field: 'unitPriceCurrency' },
  { message: orderMessages.exporterName, field: 'exporterName' },
  { message: orderMessages.supplier, field: 'supplierName' },
]

export const OrderSortConfig: EntitySortConfig<OrderSortInput>[] = [
  { message: orderMessages.updatedAt, field: 'updatedAt' },
  { message: orderMessages.createdAt, field: 'createdAt' },
  { message: orderMessages.poSort, field: 'poNo' },
  { message: orderMessages.piSort, field: 'piNo' },
  { message: orderMessages.date, field: 'issuedAt' },
  { message: orderMessages.exporterName, field: 'exporterName' },
  { message: orderMessages.currency, field: 'currency' },
  { message: orderMessages.incoterm, field: 'incoterm' },
  { message: orderMessages.deliveryPlace, field: 'deliveryPlace' },
]

export const OrderItemSortConfig: EntitySortConfig<OrderItemSortInput>[] = [
  { message: orderItemMessages.updatedAt, field: 'updatedAt' },
  { message: orderItemMessages.createdAt, field: 'createdAt' },
  { message: orderItemMessages.no, field: 'no' },
  { message: orderItemMessages.currency, field: 'currency' },
  { message: orderItemMessages.productName, field: 'productName' },
  { message: orderItemMessages.productSerial, field: 'productSerial' },
  { message: orderItemMessages.productProviderName, field: 'productProviderName' },
  { message: orderItemMessages.supplierName, field: 'supplierName' },
]

export const BatchSortConfig: EntitySortConfig<BatchSortInput>[] = [
  { message: batchMessages.updatedAt, field: 'updatedAt' },
  { message: batchMessages.createdAt, field: 'createdAt' },
  { message: batchMessages.batchNo, field: 'no' },
  { message: batchMessages.poNo, field: 'poNo' },
  { message: batchMessages.exporter, field: 'orderExporter' },
  { message: batchMessages.productName, field: 'productName' },
  { message: batchMessages.productSerial, field: 'productSerial' },
  { message: batchMessages.producedAt, field: 'producedAt' },
  { message: batchMessages.deliveredAt, field: 'deliveredAt' },
  { message: batchMessages.expiredAt, field: 'expiredAt' },
  { message: batchMessages.desiredAt, field: 'desiredAt' },
  { message: batchMessages.containerFreeTimeDueDate, field: 'containerFreeTimeDueDate' },
  {
    message: batchMessages.containerWarehouseAgreedArrivalDate,
    field: 'containerWarehouseAgreedArrivalDate',
  },
  { message: batchMessages.shipmentNo, field: 'shipmentNo' },
  { message: batchMessages.shipmentLoadPort, field: 'shipmentLoadPort' },
  {
    message: batchMessages.shipmentLoadPortDeparture,
    field: 'shipmentLoadPortDepartureCurrentDate',
  },
  { message: batchMessages.shipmentDischargePort, field: 'shipmentDischargePort' },
  {
    message: batchMessages.shipmentDischargePortArrival,
    field: 'shipmentDischargePortArrivalCurrentDate',
  },
]

export const ShipmentSortConfig: EntitySortConfig<ShipmentSortInput>[] = [
  { message: shipmentMessages.updatedAt, field: 'updatedAt' },
  { message: shipmentMessages.createdAt, field: 'createdAt' },
  { message: shipmentMessages.shipmentId, field: 'no' },
  { message: shipmentMessages.blNo, field: 'blNo' },
  { message: shipmentMessages.vesselName, field: 'vesselName' },
  { message: shipmentMessages.cargoReady, field: 'cargoReadyCurrentDate' },
  { message: shipmentMessages.loadPort, field: 'loadPort' },
  { message: shipmentMessages.loadPortDeparture, field: 'loadPortDepartureCurrentDate' },
  {
    message: shipmentMessages.firstTransitPortArrival,
    field: 'firstTransitPortArrivalCurrentDate',
  },
  {
    message: shipmentMessages.firstTransitPortDeparture,
    field: 'firstTransitPortDepartureCurrentDate',
  },
  {
    message: shipmentMessages.secondTransitPortArrival,
    field: 'secondTransitPortArrivalCurrentDate',
  },
  {
    message: shipmentMessages.secondTransitPortDeparture,
    field: 'secondTransitPortDepartureCurrentDate',
  },
  { message: shipmentMessages.dischargePort, field: 'dischargePort' },
  { message: shipmentMessages.dischargePortArrival, field: 'dischargePortArrivalCurrentDate' },
  { message: shipmentMessages.customClearance, field: 'customClearanceCurrentDate' },
  { message: shipmentMessages.warehouseArrival, field: 'warehouseArrivalCurrentDate' },
  { message: shipmentMessages.deliveryReady, field: 'deliveryReadyCurrentDate' },
]

export const ContainerSortConfig: EntitySortConfig<ContainerSortInput>[] = [
  { message: containerMessages.updatedAt, field: 'updatedAt' },
  { message: containerMessages.createdAt, field: 'createdAt' },
  { message: containerMessages.warehouseName, field: 'warehouseName' },
  { message: containerMessages.warehouseArrivalActualDate, field: 'warehouseArrivalActualDate' },
  { message: containerMessages.warehouseArrivalAgreedDate, field: 'warehouseArrivalAgreedDate' },
  { message: containerMessages.dueDate, field: 'freeTimeDueDate' },
]

export const WarehouseSortConfig: EntitySortConfig<WarehouseSortInput>[] = [
  { message: warehouseMessages.updatedAt, field: 'updatedAt' },
  { message: warehouseMessages.createdAt, field: 'createdAt' },
]

export const PartnerSortConfig: EntitySortConfig<PartnerSortInput>[] = [
  { message: partnerMessages.updatedAt, field: 'updatedAt' },
  { message: partnerMessages.createdAt, field: 'createdAt' },
  { message: partnerMessages.name, field: 'name' },
  { message: partnerMessages.code, field: 'code' },
]

export const UserSortConfig: EntitySortConfig<UserSortInput>[] = [
  { message: userMessages.updatedAt, field: 'updatedAt' },
  { message: userMessages.createdAt, field: 'createdAt' },
  { message: userMessages.firstName, field: 'firstName' },
  { message: userMessages.lastName, field: 'lastName' },
  { message: userMessages.fullName, field: 'fullName' },
]

export const FileSortConfig: EntitySortConfig<FileSortInput>[] = [
  { message: fileMessages.updatedAt, field: 'updatedAt' },
  { message: fileMessages.createdAt, field: 'createdAt' },
  { message: fileMessages.name, field: 'name' },
  { message: fileMessages.type, field: 'type' },
  { message: fileMessages.status, field: 'status' },
  { message: fileMessages.size, field: 'size' },
]

export const FileFolderSortConfig: EntitySortConfig<FolderSortInput>[] = [
  { message: fileMessages.updatedAt, field: 'updatedAt' },
  { message: fileMessages.createdAt, field: 'createdAt' },
  { message: fileMessages.name, field: 'name' },
]

export const DeliveryBoxSortConfig: SortConfig[] = [
  { message: fileMessages.documentQuantity, field: 'documentQuantity' },
  { message: fileMessages.updatedAt, field: 'updatedAt' },
]

export const TagSortConfig: EntitySortConfig<TagSortInput>[] = [
  { message: tagMessages.updatedAt, field: 'updatedAt' },
  { message: tagMessages.createdAt, field: 'createdAt' },
  { message: tagMessages.name, field: 'name' },
]

export const MaskEditSortConfig: EntitySortConfig<MaskEditSortInput>[] = [
  { message: tableTemplateMessages.updatedAt, field: 'updatedAt' },
  { message: tableTemplateMessages.createdAt, field: 'createdAt' },
  { message: tableTemplateMessages.name, field: 'name' },
]
