// @flow strict
import shipmentMessages from '@modules/shipment/messages'

import batchMessages from 'modules/batch/messages'
import containerMessages from 'modules/container/messages'
import fileMessages from 'modules/document/messages'
import notificationsMessages from 'modules/notifications/messages'
import orderMessages from 'modules/order/messages'
import orderItemMessages from 'modules/orderItem/messages'
import partnerMessages from 'modules/partner/messages'
import productMessages from 'modules/product/messages'
import tableTemplateMessages from 'modules/tableTemplate/messages'
import tagMessages from 'modules/tags/messages'
import userMessages from 'modules/user/messages'
import warehouseMessages from 'modules/warehouse/messages'
import { defaultVolumeMetric } from 'utils/metric'

import type { FilterConfig } from './types'

export const ProductFilterConfig: FilterConfig[] = [
  {
    entity: 'Product',
    field: 'archived',
    type: 'archived',
    message: productMessages.status,
    defaultValue: false,
  },
  // Add this for bulk filter
  {
    entity: 'Product',
    field: 'bulkFilter',
    type: 'bulkFilter',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  {
    entity: 'Product',
    field: 'createdAt',
    type: 'date_range',
    message: productMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Product',
    field: 'updatedAt',
    type: 'date_range',
    message: productMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Product',
    field: 'tagIdsWithOperator',
    type: 'product_tags_with_operator',
    message: productMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Product',
    field: 'notTagIds',
    type: 'product_tags',
    message: productMessages.tagsWithout,
    defaultValue: [],
  },
]

export const ProductProviderFilterConfig: FilterConfig[] = [
  {
    entity: 'ProductProvider',
    field: 'archived',
    type: 'archived',
    message: productMessages.status,
    defaultValue: false,
  },
  {
    entity: 'ProductProvider',
    field: 'connectionIds',
    type: 'connection_ids',
    message: productMessages.connection,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'createdAt',
    type: 'date_range',
    message: productMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'ProductProvider',
    field: 'updatedAt',
    type: 'date_range',
    message: productMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'ProductProvider',
    field: 'tagIdsWithOperator',
    type: 'product_tags_with_operator',
    message: productMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'ProductProvider',
    field: 'notTagIds',
    type: 'product_tags',
    message: productMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'importerId',
    type: 'importer_id',
    message: productMessages.importer,
    defaultValue: null,
    hidden: true,
  },
  {
    entity: 'ProductProvider',
    field: 'exporterId',
    type: 'exporter_id',
    message: productMessages.exporter,
    defaultValue: null,
    hidden: true,
  },
  {
    entity: 'ProductProvider',
    field: 'supplierId',
    type: 'supplier_id',
    message: productMessages.supplier,
    defaultValue: null,
    hidden: true,
  },
  {
    entity: 'ProductProvider',
    field: 'importerIds',
    type: 'importer_ids',
    message: productMessages.importers,
    defaultValue: [],
    hidden: true,
  },
  {
    entity: 'ProductProvider',
    field: 'exporterIds',
    type: 'exporter_ids',
    message: productMessages.exporters,
    defaultValue: [],
    hidden: true,
  },
  {
    entity: 'ProductProvider',
    field: 'supplierIds',
    type: 'supplier_ids',
    message: productMessages.suppliers,
    defaultValue: [],
    hidden: true,
  },
]

export const OrderFilterConfig: FilterConfig[] = [
  {
    entity: 'Order',
    field: 'ids',
    type: 'order_ids',
    message: orderMessages.order,
    defaultValue: [],
  },
  // Add this for bulk filter
  {
    entity: 'Order',
    field: 'bulkFilter',
    type: 'bulkFilter',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  // Add this for keywords bulk filter
  {
    entity: 'Order',
    field: 'keywords',
    type: 'keywords',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  {
    entity: 'Order',
    field: 'archived',
    type: 'archived',
    message: orderMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'createdAt',
    type: 'date_range',
    message: orderMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'updatedAt',
    type: 'date_range',
    message: orderMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'deliveryDate',
    type: 'date_range',
    message: orderMessages.deliveryDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'importerIds',
    type: 'importer_ids',
    message: orderMessages.importer,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'exporterIds',
    type: 'exporter_ids',
    message: orderMessages.exporter,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'followerIds',
    type: 'users',
    message: orderMessages.followers,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'tagIdsWithOperator',
    type: 'order_tags_with_operator',
    message: orderMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Order',
    field: 'notTagIds',
    type: 'order_tags',
    message: orderMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'completelyBatched',
    type: 'completely_batched',
    message: orderMessages.completelyBatched,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'completelyShipped',
    type: 'completely_shipped',
    message: orderMessages.completelyShipped,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'organizationIds',
    type: 'organization_ids',
    message: orderMessages.sharedPartners,
    defaultValue: [],
  },
  {
    entity: 'Batch',
    field: 'batchDeliveredAt',
    type: 'date_range',
    message: batchMessages.deliveredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchExpiredAt',
    type: 'date_range',
    message: batchMessages.expiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchProducedAt',
    type: 'date_range',
    message: batchMessages.producedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchDesiredAt',
    type: 'date_range',
    message: batchMessages.desiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchTotalVolume',
    type: 'volume_range',
    message: batchMessages.totalVolume,
    defaultValue: {
      min: null,
      max: null,
      metric: defaultVolumeMetric,
    },
  },
  {
    entity: 'Batch',
    field: 'batchTagIdsWithOperator',
    type: 'batch_tags_with_operator',
    message: batchMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Product',
    field: 'productIds',
    type: 'product_ids',
    message: productMessages.product,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productTagIdsWithOperator',
    type: 'product_tags_with_operator',
    message: productMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderIds',
    type: 'product_provider_ids',
    message: productMessages.productProvider,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderSupplierIds',
    type: 'supplier_ids',
    message: productMessages.supplier,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentArchived',
    type: 'archived',
    message: shipmentMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Shipment',
    field: 'shipmentForwarderIds',
    type: 'forwarder_ids',
    message: shipmentMessages.forwarder,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentWarehouseIds',
    type: 'warehouse_ids',
    message: shipmentMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentCargoReady',
    type: 'date_range',
    message: shipmentMessages.cargoReady,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadPortDeparture',
    type: 'date_range',
    message: shipmentMessages.loadPortDeparture,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadPorts',
    type: 'ports',
    message: shipmentMessages.loadPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortArrival',
    type: 'date_range',
    message: shipmentMessages.firstTransitPortArrival,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortDeparture',
    type: 'date_range',
    message: shipmentMessages.firstTransitPortDeparture,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPorts',
    type: 'ports',
    message: shipmentMessages.firstTransitPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortArrival',
    type: 'date_range',
    message: shipmentMessages.secondTransitPortArrival,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortDeparture',
    type: 'date_range',
    message: shipmentMessages.secondTransitPortDeparture,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPorts',
    type: 'ports',
    message: shipmentMessages.secondTransitPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentDischargePortArrival',
    type: 'date_range',
    message: shipmentMessages.dischargePortArrival,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDischargePorts',
    type: 'ports',
    message: shipmentMessages.dischargePort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentCustomClearance',
    type: 'date_range',
    message: shipmentMessages.customClearance,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentWarehouseArrival',
    type: 'date_range',
    message: shipmentMessages.warehouseArrival,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDeliveryReady',
    type: 'date_range',
    message: shipmentMessages.deliveryReady,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCreatedAt',
    type: 'date_range',
    message: shipmentMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentUpdatedAt',
    type: 'date_range',
    message: shipmentMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentTagIdsWithOperator',
    type: 'shipment_tags_with_operator',
    message: shipmentMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Container',
    field: 'containerContainerType',
    type: 'container_type',
    message: containerMessages.containerType,
    defaultValue: null,
  },
  {
    entity: 'Container',
    field: 'containerContainerOption',
    type: 'container_option',
    message: containerMessages.containerOption,
    defaultValue: null,
  },
  {
    entity: 'Container',
    field: 'containerWarehouseIds',
    type: 'warehouse_ids',
    message: containerMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerTagIds',
    type: 'container_tags',
    message: containerMessages.tags,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerCreatedAt',
    type: 'date_range',
    message: containerMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerUpdatedAt',
    type: 'date_range',
    message: containerMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerWarehouseArrivalAgreedDate',
    type: 'date_range',
    message: containerMessages.warehouseArrivalAgreedDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerWarehouseArrivalActualDate',
    type: 'date_range',
    message: containerMessages.warehouseArrivalActualDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerFreeTimeDueDate',
    type: 'date_range',
    message: containerMessages.dueDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerFreeTimeOverdue',
    type: 'free_time_overdue',
    message: containerMessages.freeTimeOverdue,
    defaultValue: false,
  },
  {
    entity: 'Container',
    field: 'containerTagIdsWithOperator',
    type: 'container_tags_with_operator',
    message: containerMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
]

export const OrderItemFilterConfig: FilterConfig[] = [
  {
    entity: 'OrderItem',
    field: 'archived',
    type: 'archived',
    message: orderItemMessages.status,
    defaultValue: false,
  },
  {
    entity: 'OrderItem',
    field: 'createdAt',
    type: 'date_range',
    message: orderItemMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'OrderItem',
    field: 'updatedAt',
    type: 'date_range',
    message: orderItemMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
]

export const BatchFilterConfig: FilterConfig[] = [
  {
    entity: 'Batch',
    field: 'archived',
    type: 'archived',
    message: batchMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Batch',
    field: 'connectionIds',
    type: 'connection_ids',
    message: batchMessages.connection,
    defaultValue: [],
  },
  {
    entity: 'Batch',
    field: 'deliveredAt',
    type: 'date_range',
    message: batchMessages.deliveredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'expiredAt',
    type: 'date_range',
    message: batchMessages.expiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'producedAt',
    type: 'date_range',
    message: batchMessages.producedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'desiredAt',
    type: 'date_range',
    message: batchMessages.desiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'tagIdsWithOperator',
    type: 'batch_tags_with_operator',
    message: batchMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Batch',
    field: 'notTagIds',
    type: 'batch_tags',
    message: batchMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Batch',
    field: 'hasShipment',
    type: 'has_shipment',
    message: batchMessages.hasShipment,
    defaultValue: false,
  },
  {
    entity: 'Batch',
    field: 'createdAt',
    type: 'date_range',
    message: batchMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'updatedAt',
    type: 'date_range',
    message: batchMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'orderIds',
    type: 'order_ids',
    message: batchMessages.order,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderImporterIds',
    type: 'importer_ids',
    message: batchMessages.importer,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderExporterIds',
    type: 'exporter_ids',
    message: batchMessages.exporter,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderTagIdsWithOperator',
    type: 'order_tags_with_operator',
    message: orderMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Order',
    field: 'orderNotTagIds',
    type: 'order_tags',
    message: orderMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderSharedPartnerOrganizationIds',
    type: 'organization_ids',
    message: orderMessages.sharedPartners,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productIds',
    type: 'product_ids',
    message: batchMessages.product,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderIds',
    type: 'product_provider_ids',
    message: batchMessages.productProvider,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderSupplierIds',
    type: 'supplier_ids',
    message: batchMessages.supplier,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerIds',
    type: 'container_ids',
    message: batchMessages.container,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerWarehouseIds',
    type: 'warehouse_ids',
    message: batchMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentIds',
    type: 'shipment_ids',
    message: batchMessages.shipment,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentFollowerIds',
    type: 'users',
    message: batchMessages.followers,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentTagIds',
    type: 'shipment_tags',
    message: batchMessages.shipmentTags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Shipment',
    field: 'shipmentNotTagIds',
    type: 'shipment_tags',
    message: batchMessages.shipmentTagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentArchived',
    type: 'archived',
    message: batchMessages.shipmentStatus,
    defaultValue: false,
  },
  {
    entity: 'Shipment',
    field: 'shipmentCargoReadyInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentCargoReadyInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCargoReadyResultDate',
    type: 'date_range',
    message: batchMessages.shipmentCargoReadyResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCargoReadyCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentCargoReadyCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadTypes',
    type: 'shipment_load_type',
    message: batchMessages.shipmentLoadType,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadPortDepartureInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentLoadPortDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadPortDepartureResultDate',
    type: 'date_range',
    message: batchMessages.shipmentLoadPortDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentLoadPortDepartureCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentLoadPortDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortArrivalInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortArrivalResultDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortArrivalCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortDepartureInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortDepartureResultDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentFirstTransitPortDepartureCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentFirstTransitPortDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortArrivalInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortArrivalResultDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortArrivalCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortDepartureInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortDepartureResultDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentSecondTransitPortDepartureCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentSecondTransitPortDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDischargePortArrivalInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentDischargePortArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDischargePortArrivalResultDate',
    type: 'date_range',
    message: batchMessages.shipmentDischargePortArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDischargePortArrivalCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentDischargePortArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCustomClearanceInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentCustomClearanceInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCustomClearanceResultDate',
    type: 'date_range',
    message: batchMessages.shipmentCustomClearanceResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentCustomClearanceCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentCustomClearanceCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentWarehouseArrivalInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentWarehouseArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentWarehouseArrivalResultDate',
    type: 'date_range',
    message: batchMessages.shipmentWarehouseArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentWarehouseArrivalCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentWarehouseArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDeliveryReadyInitialDate',
    type: 'date_range',
    message: batchMessages.shipmentDeliveryReadyInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDeliveryReadyResultDate',
    type: 'date_range',
    message: batchMessages.shipmentDeliveryReadyResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'shipmentDeliveryReadyCurrentDate',
    type: 'date_range',
    message: batchMessages.shipmentDeliveryReadyCurrentDate,
    defaultValue: { after: null, before: null },
  },
]

export const ShipmentFilterConfig: FilterConfig[] = [
  {
    entity: 'Shipment',
    field: 'ids',
    type: 'shipment_ids',
    message: shipmentMessages.shipment,
    defaultValue: [],
  },
  // Add this for bulk filter
  {
    entity: 'Shipment',
    field: 'bulkFilter',
    type: 'bulkFilter',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  // Add this for keywords bulk filter
  {
    entity: 'Shipment',
    field: 'keywords',
    type: 'keywords',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  {
    entity: 'Shipment',
    field: 'archived',
    type: 'archived',
    message: shipmentMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Shipment',
    field: 'unseenNotification',
    type: 'has_unseen_notification',
    message: shipmentMessages.unseenNotification,
    defaultValue: false,
  },
  {
    entity: 'Shipment',
    field: 'importerIds',
    type: 'importer_ids',
    message: shipmentMessages.importer,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'exporterIds',
    type: 'exporter_ids',
    message: shipmentMessages.exporter,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'forwarderIds',
    type: 'forwarder_ids',
    message: shipmentMessages.forwarder,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'booked',
    type: 'booked',
    message: shipmentMessages.booked,
    defaultValue: true,
  },
  {
    entity: 'Shipment',
    field: 'loadTypes',
    type: 'shipment_load_type',
    message: shipmentMessages.loadType,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'warehouseIds',
    type: 'warehouse_ids',
    message: shipmentMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'cargoReadyWarehouseIds',
    type: 'warehouse_ids',
    message: shipmentMessages.cargoReadyWarehouse,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'cargoReadyInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusCargoReadyInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'cargoReadyResultDate',
    type: 'date_range',
    message: shipmentMessages.statusCargoReadyResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'cargoReadyCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusCargoReadyCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'loadPortDepartureInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusLoadPortDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'loadPortDepartureResultDate',
    type: 'date_range',
    message: shipmentMessages.statusLoadPortDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'loadPortDepartureCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusLoadPortDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'loadPorts',
    type: 'ports',
    message: shipmentMessages.loadPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortArrivalInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortArrivalResultDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortArrivalCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortDepartureInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortDepartureResultDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPortDepartureCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusFirstTransitDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'firstTransitPorts',
    type: 'ports',
    message: shipmentMessages.firstTransitPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortArrivalInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortArrivalResultDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortArrivalCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortDepartureInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitDepartureInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortDepartureResultDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitDepartureResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPortDepartureCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusSecondTransitDepartureCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'secondTransitPorts',
    type: 'ports',
    message: shipmentMessages.secondTransitPort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'dischargePortArrivalInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusDischargePortArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'dischargePortArrivalResultDate',
    type: 'date_range',
    message: shipmentMessages.statusDischargePortArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'dischargePortArrivalCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusDischargePortArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'dischargePorts',
    type: 'ports',
    message: shipmentMessages.dischargePort,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'customClearanceInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusCustomClearanceInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'customClearanceResultDate',
    type: 'date_range',
    message: shipmentMessages.statusCustomClearanceResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'customClearanceCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusCustomClearanceCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'warehouseArrivalInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusWarehouseArrivalInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'warehouseArrivalResultDate',
    type: 'date_range',
    message: shipmentMessages.statusWarehouseArrivalResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'warehouseArrivalCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusWarehouseArrivalCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'deliveryReadyInitialDate',
    type: 'date_range',
    message: shipmentMessages.statusDeliveryReadyInitialDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'deliveryReadyResultDate',
    type: 'date_range',
    message: shipmentMessages.statusDeliveryReadyResultDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'deliveryReadyCurrentDate',
    type: 'date_range',
    message: shipmentMessages.statusDeliveryReadyCurrentDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'createdAt',
    type: 'date_range',
    message: shipmentMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'updatedAt',
    type: 'date_range',
    message: shipmentMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Shipment',
    field: 'followerIds',
    type: 'users',
    message: shipmentMessages.followers,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'sharedPartnerIds',
    type: 'organization_ids',
    message: shipmentMessages.sharedPartners,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'tagIdsWithOperator',
    type: 'shipment_tags_with_operator',
    message: shipmentMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Shipment',
    field: 'notTagIds',
    type: 'shipment_tags',
    message: shipmentMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerIds',
    type: 'container_ids',
    message: containerMessages.container,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerContainerType',
    type: 'container_type',
    message: containerMessages.containerType,
    defaultValue: null,
  },
  {
    entity: 'Container',
    field: 'containerContainerOption',
    type: 'container_option',
    message: containerMessages.containerOption,
    defaultValue: null,
  },
  {
    entity: 'Container',
    field: 'containerWarehouseIds',
    type: 'warehouse_ids',
    message: containerMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerTagIdsWithOperator',
    type: 'container_tags_with_operator',
    message: containerMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Container',
    field: 'containerNotTagIds',
    type: 'container_tags',
    message: containerMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'containerCreatedAt',
    type: 'date_range',
    message: containerMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerUpdatedAt',
    type: 'date_range',
    message: containerMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerWarehouseArrivalAgreedDate',
    type: 'date_range',
    message: containerMessages.warehouseArrivalAgreedDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerWarehouseArrivalActualDate',
    type: 'date_range',
    message: containerMessages.warehouseArrivalActualDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerFreeTimeDueDate',
    type: 'date_range',
    message: containerMessages.dueDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'containerFreeTimeOverdue',
    type: 'free_time_overdue',
    message: containerMessages.freeTimeOverdue,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'orderIds',
    type: 'order_ids',
    message: orderMessages.order,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderArchived',
    type: 'archived',
    message: orderMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'orderExporterIds',
    type: 'exporter_ids',
    message: orderMessages.exporter,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderTagIdsWithOperator',
    type: 'order_tags_with_operator',
    message: orderMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Order',
    field: 'orderNotTagIds',
    type: 'order_tags',
    message: orderMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderCreatedAt',
    type: 'date_range',
    message: orderMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'orderUpdatedAt',
    type: 'date_range',
    message: orderMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Order',
    field: 'orderCompletelyBatched',
    type: 'completely_batched',
    message: orderMessages.completelyBatched,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'orderCompletelyShipped',
    type: 'completely_shipped',
    message: orderMessages.completelyShipped,
    defaultValue: false,
  },
  {
    entity: 'OrderItem',
    field: 'orderItemTagIdsWithOperator',
    type: 'order_item_tags_with_operator',
    message: orderItemMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'OrderItem',
    field: 'orderItemNotTagIds',
    type: 'order_item_tags',
    message: orderItemMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Batch',
    field: 'batchDeliveredAt',
    type: 'date_range',
    message: batchMessages.deliveredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchExpiredAt',
    type: 'date_range',
    message: batchMessages.expiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchProducedAt',
    type: 'date_range',
    message: batchMessages.producedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchDesiredAt',
    type: 'date_range',
    message: batchMessages.desiredAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Batch',
    field: 'batchTotalVolume',
    type: 'volume_range',
    message: batchMessages.totalVolume,
    defaultValue: {
      min: null,
      max: null,
      metric: defaultVolumeMetric,
    },
  },
  {
    entity: 'Batch',
    field: 'batchTagIdsWithOperator',
    type: 'batch_tags_with_operator',
    message: batchMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Batch',
    field: 'batchNotTagIds',
    type: 'batch_tags',
    message: batchMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productIds',
    type: 'product_ids',
    message: productMessages.product,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productTagIdsWithOperator',
    type: 'product_tags_with_operator',
    message: productMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'Product',
    field: 'productNotTagIds',
    type: 'product_tags',
    message: productMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderIds',
    type: 'product_provider_ids',
    message: productMessages.productProvider,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderExporterIds',
    type: 'exporter_ids',
    message: productMessages.exporter,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderSupplierIds',
    type: 'supplier_ids',
    message: productMessages.supplier,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderOrigin',
    type: 'country',
    message: productMessages.origin,
    defaultValue: null,
  },
]

export const ContainerFilterConfig: FilterConfig[] = [
  {
    entity: 'Container',
    field: 'archived',
    type: 'archived',
    message: containerMessages.status,
    defaultValue: false,
  },
  // Add this for bulk filter
  {
    entity: 'Container',
    field: 'bulkFilter',
    type: 'bulkFilter',
    message: '',
    defaultValue: {},
    hidden: true,
  },
  {
    entity: 'Container',
    field: 'warehouseArrivalActualDate',
    type: 'date_range',
    message: containerMessages.warehouseArrivalActualDate,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'warehouseIds',
    type: 'warehouse_ids',
    message: containerMessages.warehouse,
    defaultValue: [],
  },
  {
    entity: 'Container',
    field: 'createdAt',
    type: 'date_range',
    message: containerMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Container',
    field: 'updatedAt',
    type: 'date_range',
    message: containerMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
]

export const WarehouseFilterConfig: FilterConfig[] = [
  {
    entity: 'Warehouse',
    field: 'archived',
    type: 'archived',
    message: warehouseMessages.status,
    defaultValue: false,
  },
  {
    entity: 'Warehouse',
    field: 'createdAt',
    type: 'date_range',
    message: warehouseMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Warehouse',
    field: 'updatedAt',
    type: 'date_range',
    message: warehouseMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
]

export const PartnerFilterConfig: FilterConfig[] = [
  {
    entity: 'Partner',
    field: 'createdAt',
    type: 'date_range',
    message: partnerMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Partner',
    field: 'updatedAt',
    type: 'date_range',
    message: partnerMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Partner',
    field: 'types',
    type: 'organization_types',
    message: partnerMessages.type,
    defaultValue: [],
  },
  {
    entity: 'Partner',
    field: 'connectionIds',
    type: 'connection_ids',
    message: partnerMessages.connection,
    defaultValue: [],
  },
]

export const UserFilterConfig: FilterConfig[] = [
  {
    entity: 'User',
    field: 'createdAt',
    type: 'date_range',
    message: userMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'User',
    field: 'updatedAt',
    type: 'date_range',
    message: userMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'User',
    field: 'organizationIds',
    type: 'organization_ids',
    message: userMessages.organizations,
    defaultValue: [],
  },
  {
    entity: 'User',
    field: 'connectionIds',
    type: 'connection_ids',
    message: userMessages.connection,
    defaultValue: [],
  },
]

export const FileFilterConfig: FilterConfig[] = [
  {
    entity: 'Document',
    field: 'createdAt',
    type: 'date_range',
    message: fileMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Document',
    field: 'updatedAt',
    type: 'date_range',
    message: fileMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Document',
    field: 'hasEntity',
    type: 'has_entity',
    message: fileMessages.hasEntity,
    defaultValue: false,
  },
  {
    entity: 'Order',
    field: 'orderIds',
    type: 'order_ids',
    message: fileMessages.order,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderIds',
    type: 'product_provider_ids',
    message: fileMessages.productProvider,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productIds',
    type: 'product_ids',
    message: fileMessages.product,
    defaultValue: [],
  },
  {
    entity: 'Shipment',
    field: 'shipmentIds',
    type: 'shipment_ids',
    message: fileMessages.shipment,
    defaultValue: [],
  },
  {
    entity: 'Organization',
    field: 'createdByOrganizationIds',
    type: 'organization_ids',
    message: fileMessages.uploader,
    defaultValue: [],
  },
  {
    entity: 'File',
    field: 'tagIdsWithOperator',
    type: 'file_tags_with_operator',
    message: fileMessages.tags,
    defaultValue: { operator: 'AND', ids: [] },
  },
  {
    entity: 'File',
    field: 'notTagIds',
    type: 'file_tags',
    message: fileMessages.tagsWithout,
    defaultValue: [],
  },
  {
    entity: 'File',
    field: 'type',
    type: 'file_type',
    message: fileMessages.type,
    defaultValue: [],
  },
]

export const FileFolderFilterConfig: FilterConfig[] = [
  {
    entity: 'Shipment',
    field: 'shipmentIds',
    type: 'shipment_ids',
    message: fileMessages.shipment,
    defaultValue: [],
  },
  {
    entity: 'Order',
    field: 'orderIds',
    type: 'order_ids',
    message: fileMessages.order,
    defaultValue: [],
  },
  {
    entity: 'Product',
    field: 'productIds',
    type: 'product_ids',
    message: fileMessages.product,
    defaultValue: [],
  },
  {
    entity: 'ProductProvider',
    field: 'productProviderIds',
    type: 'product_provider_ids',
    message: fileMessages.productProvider,
    defaultValue: [],
  },
  {
    entity: 'Organization',
    field: 'organizationIds',
    type: 'organization_ids',
    message: fileMessages.uploader,
    defaultValue: [],
  },
]

export const TagFilterConfig: FilterConfig[] = [
  {
    entity: 'Tag',
    field: 'createdAt',
    type: 'date_range',
    message: tagMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Tag',
    field: 'updatedAt',
    type: 'date_range',
    message: tagMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
]

export const MaskEditFilterConfig: FilterConfig[] = [
  {
    entity: 'Table Template',
    field: 'createdAt',
    type: 'date_range',
    message: tableTemplateMessages.createdAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Table Template',
    field: 'updatedAt',
    type: 'date_range',
    message: tableTemplateMessages.updatedAt,
    defaultValue: { after: null, before: null },
  },
  {
    entity: 'Table Template',
    field: 'type',
    type: 'mask_edit_type',
    message: tableTemplateMessages.type,
    defaultValue: [],
  },
]

export const NotificationFilterConfig: FilterConfig[] = [
  {
    entity: 'Notification',
    field: 'ids',
    type: 'notification_ids',
    message: notificationsMessages.notifications,
    defaultValue: [],
  },
]
