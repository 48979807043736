import { ServerError, ServerParseError } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { either, has, is, isEmpty, isNil, map, omit, pipe, reject, when } from 'ramda'

import { AnyMxRecord } from 'dns'

import { IntValue, MetricValue, StringValue, Value, Values } from '@graphql/server/typescript'

import {
  DeepPartial,
  Payload,
  UniversalBadRequest,
  UniversalForbidden,
  UniversalNotFound,
} from '@types'
import { defaultDistanceMetric } from '@utils/metric'

import { isDateObject } from './date'
import { getByPath, getByPathWithDefault, isEquals } from './fp'

export const replaceUndefined: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    map((x) => (x === undefined ? null : x)),
    map((a) => replaceUndefined(a))
  )
)

export const removeNulls: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    reject(isNil),
    map((a) => removeNulls(a))
  )
)

export const removeEmpty: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    reject(isEmpty),
    map((a) => removeEmpty(a))
  )
)

export const replaceEmptyString: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    map((x) => (x === '' ? null : x)),
    map((a) => replaceEmptyString(a))
  )
)

export const removeTypename: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    (x) => (is(Object, x) && !is(Array, x) && has('__typename', x) ? omit(['__typename'], x) : x),
    map((a) => removeTypename(a))
  )
)

export const removeId: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    (x) => (is(Object, x) && !is(Array, x) ? omit(['id'], x) : x),
    map((a) => removeId(a))
  )
)

export const cleanUpData: (params: any) => any = pipe(removeTypename, removeNulls)

export const cleanFalsyAndTypeName: (params: any) => any = pipe(
  removeTypename,
  removeNulls,
  removeEmpty
)

export const isServerError = (value: ErrorResponse['networkError']): value is ServerError =>
  !!(value && 'results' in value)

export const isServerParseError = (
  value: ErrorResponse['networkError']
): value is ServerParseError => !!(value && 'bodyText' in value)

export const isForbidden = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalForbidden => {
  return getByPath('__typename', data) === 'Forbidden'
}

export const isNotFound = (data: { [key: string]: any }): data is UniversalNotFound => {
  return getByPath('__typename', data) === 'NotFound'
}

export const isBadRequest = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalBadRequest => {
  return getByPath('__typename', data) === 'BadRequest'
}

export const isUnretrievable = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalBadRequest | UniversalForbidden | UniversalNotFound => {
  return isBadRequest(data) || isForbidden(data) || isNotFound(data)
}

export const isRetrievable = <T extends { __typename: string; [key: string]: any }>(
  data: Payload<T>
): data is T => {
  return !isUnretrievable(data)
}

export const isUnavailable = (
  data?: { __typename: string; [key: string]: any } | null
): data is UniversalBadRequest | UniversalForbidden | UniversalNotFound | null | undefined => {
  return !data || isUnretrievable(data)
}

export const isAvailable = <T extends { __typename: string; [key: string]: any }>(
  data?: Payload<T> | null | undefined
): data is T => {
  return !isUnavailable(data)
}

export function assertIsIntValue(value: DeepPartial<Value>): asserts value is IntValue {
  if (value.__typename !== 'IntValue' || typeof value.int !== 'number') {
    throw new Error(`value is not an IntValue`)
  }
}

export function assertIsStringValue(value: DeepPartial<Value>): asserts value is StringValue {
  if (value.__typename !== 'StringValue' || typeof value.string !== 'string') {
    throw new Error(`value is not a StringValue`)
  }
}

export function assertIsValuesValue(value: DeepPartial<Value>): asserts value is Values {
  if (value.__typename !== 'Values' || !Array.isArray(value.values)) {
    throw new Error(`value is not a ValuesValue`)
  }
}

/** Does the change include an entity with an ID (modelled) */
export function isModelledEntityValue(
  value: DeepPartial<Value>
): value is { __typename: 'EntityValue'; entity: { __typename: any; id: string } } {
  return !!('entity' in value && value.entity && 'id' in value.entity)
}

/** Does the value include an entity with an ID (modelled) */
export function assertIsModelledEntityValue(
  value: DeepPartial<Value>
): asserts value is { __typename: 'EntityValue'; entity: { __typename: any; id: string } } {
  if (!isModelledEntityValue(value)) {
    throw new Error(`value is not a modelled EntityValue`)
  }
}

export const some = <T extends { __typename: string; [key: string]: any }>(
  data: Payload<T> | null | undefined
): T | null => {
  return !isUnavailable(data) ? data : null
}

export const arrayToHash = ({
  array = [],
  key = 'id',
  removeFalsy = true,
}: {
  array: any[]
  key?: string
  removeFalsy?: boolean
}) => {
  return array.reduce((arr, obj: any) => {
    if (removeFalsy) {
      if (obj[key]) {
        arr[obj[key]] = obj
      }
    } else {
      arr[obj[key]] = obj
    }

    return arr
  }, {})
}

export const extractForbiddenId = (data: {
  __typename: string
  [key: string]: any
}): { __typename: string; [key: string]: any } => {
  if (isForbidden(data)) {
    // @ts-ignore // TODO
    const id = getByPathWithDefault(null, 'reference.id', data)
    if (id) {
      return { ...data, id }
    }
  }
  return data
}

type FilesType = {
  id: string
  name: string
  type: string
  memo: string | null
}

/**
 * gets the deleted between two array objects with key as reference
 */
export const findDeletedArrayData = (
  key: string,
  originalValues: Array<FilesType> | null,
  newValues: Array<FilesType>
) => {
  // convert to objects by id
  const origById =
    originalValues?.reduce((arr, value) => {
      // eslint-disable-next-line
      arr[value.id] = value
      return arr
    }, {}) ?? {}

  const newById = newValues.reduce((arr, value) => {
    // eslint-disable-next-line
    arr[value.id] = value
    return arr
  }, {})

  const deleted = Object.keys(origById).reduce((arr, origId) => {
    if (!newById[origId]) {
      // @ts-ignore // TODO
      arr.push(origById[origId])
    }

    return arr
  }, [])

  return deleted
}

// For String and Number fields. Can be used for { [k: string]: any } in certain situations.
export const parseGenericField = (
  key: string,
  originalValue: any | null,
  newValue: any | null
): { [k: string]: any } => {
  if (!isEquals(originalValue, newValue)) {
    return { [key]: newValue }
  }
  return {}
}

// Use for Textarea fields. The native input cannot take null, so it will hard-code empty string. We should treat that data as null though.
export const parseMemoField = (
  key: string,
  originalMemo: string | null,
  newMemo: string | null
): { [k: string]: any } => {
  const parsedOriginalMemo = originalMemo === '' ? null : originalMemo
  const parsedNewMemo = newMemo === '' ? null : newMemo

  if (!isEquals(parsedOriginalMemo, parsedNewMemo)) return { [key]: parsedNewMemo }
  return {}
}

// Use for Enum fields. Cannot have empty string as value.
export const parseEnumField = (
  key: string,
  originalEnum: string | null,
  newEnum: string | null
): { [k: string]: any } => {
  const parsedOriginalEnum = originalEnum || null
  const parsedNewEnum = newEnum || null

  if (!isEquals(parsedOriginalEnum, parsedNewEnum)) return { [key]: parsedNewEnum }
  return {}
}

// Use for Date fields. Need to parse into Date { [k: string]: any }.
export const parseDateField = (
  key: string,
  originalDate: string | null,
  newDate: string | null
): { [k: string]: any } => {
  const parsedOriginalDate = originalDate ? new Date(originalDate) : null
  const parsedNewDate = newDate ? new Date(newDate) : null

  if (!isEquals(parsedOriginalDate, parsedNewDate)) return { [key]: parsedNewDate }
  return {}
}

// Use for Datetime fields.
export const parseDatetimeField = (
  key: string,
  originalDate: string | null,
  newDate: string | null
): { [k: string]: any } => {
  const parsedOriginalDate = originalDate || null
  const parsedNewDate = newDate || null

  if (!isEquals(parsedOriginalDate, parsedNewDate)) return { [key]: parsedNewDate }
  return {}
}

// Use for Array of Ids.
export const parseArrayOfIdsField = (
  key: string,
  originalArray: Array<{ [k: string]: any }> | null,
  newArray: Array<{ [k: string]: any }>
): { [k: string]: any } => {
  const originalArrayOfIds = (originalArray || []).map(({ id }) => id)
  const newArrayOfIds = newArray ? newArray.map(({ id }) => id) : []

  if (!isEquals(originalArrayOfIds, newArrayOfIds)) return { [key]: newArrayOfIds }
  return {}
}

// Use for Tag Fields. This uses the new format
export const parseTagsField = (key: string, originalArray: any[] | null, newArray: any[]): any => {
  const originalArrayOfIds = (originalArray || []).map(({ id }) => id).filter(Boolean)
  const newArrayOfIds = newArray ? newArray.map(({ id }) => id) : []

  if (!isEquals(originalArrayOfIds, newArrayOfIds)) {
    const deletedTags = findDeletedArrayData('id', originalArray ?? [], newArray ?? []).map(
      (tag) => ({
        // @ts-ignore // TODO
        id: tag.id,
        deleted: true,
      })
    )

    const newTags = newArray ? newArray.map(({ id }) => ({ id })) : []

    return { [key]: [...newTags, ...deletedTags] }
  }
  return {}
}

// Use for Single Id.
export const parseParentIdField = (
  key: string,
  originalParent: { [k: string]: any } | null,
  newParent: { [k: string]: any } | null
): { [k: string]: any } => {
  const originalParentId = getByPathWithDefault(null, 'id', originalParent)
  const newParentId = getByPathWithDefault(null, 'id', newParent)

  if (!isEquals(originalParentId, newParentId)) return { [key]: newParentId }
  return {}
}

// // Use to apply nested logic to child entities. Look at existing uses to understand more how to use it.
export const parseArrayOfChildrenField = (
  key: string,
  originalChildren: Array<{ [k: string]: any }> | null,
  newChildren: Array<{ [k: string]: any }>,
  parseInside: (
    oldChild: { [k: string]: any } | null,
    newChild: { [k: string]: any }
  ) => { [k: string]: any },
  forceSendIds = false
) => {
  if (!forceSendIds && isEquals(originalChildren, newChildren)) return {}

  const parsedNewChildren = newChildren.flatMap(
    (newChild: {
      [k: string]: any
    }): {
      [k: string]: any
    } => {
      const oldChild =
        (originalChildren || []).find(
          (originalChild: { [k: string]: any }): boolean => originalChild.id === newChild.id
        ) || null

      // eslint-disable-next-line
      return parseInside(oldChild, newChild)
    }
  )

  return { [key]: parsedNewChildren }
}

type CustomFieldsType = {
  mask: { [k: string]: any } | null
  fieldValues: Array<{
    value: { string: string | null }
    fieldDefinition: { [k: string]: any }
  }>
}

// Use for Custom Fields. If there is at least one change in fieldValues, we need to send all fieldValues.
export const parseCustomFieldsField = (
  key: string,
  originalCustomFields: CustomFieldsType | null,
  newCustomFields: CustomFieldsType
): { [k: string]: any } => {
  if (isEquals(originalCustomFields, newCustomFields)) return {}

  const originalMaskId = getByPathWithDefault(null, 'mask.id', originalCustomFields)
  const newMaskId = getByPathWithDefault(null, 'mask.id', newCustomFields)

  const parsedOriginalFieldValues = getByPathWithDefault(
    [],
    'fieldValues',
    originalCustomFields
  ).map((fieldValue) => {
    const value = {
      // @ts-ignore // TODO
      string: getByPathWithDefault(null, 'value.string', fieldValue),
    }
    // @ts-ignore // TODO
    const fieldDefinitionId = getByPathWithDefault(null, 'fieldDefinition.id', fieldValue)

    return { value, fieldDefinitionId }
  })
  const parsedNewFieldValues = getByPathWithDefault([], 'fieldValues', newCustomFields).map(
    (fieldValue) => {
      const value = {
        // @ts-ignore // TODO
        string: getByPathWithDefault(null, 'value.string', fieldValue),
      }
      // @ts-ignore // TODO
      const fieldDefinitionId = getByPathWithDefault(null, 'fieldDefinition.id', fieldValue)

      return { value, fieldDefinitionId }
    }
  )

  const parsedOriginalCustomFields = {
    maskId: originalMaskId,
    fieldValues: parsedOriginalFieldValues,
  }
  const parsedNewCustomFields = {
    maskId: newMaskId,
    fieldValues: parsedNewFieldValues,
  }

  if (!isEquals(parsedOriginalCustomFields, parsedNewCustomFields))
    return {
      [key]: {
        ...parseGenericField('maskId', originalMaskId, newMaskId),
        ...parseGenericField('fieldValues', parsedOriginalFieldValues, parsedNewFieldValues),
      },
    }
  return {}
}

/**
 * Use for Documents fields. Need to send ids even for new files.
 */
export const parseFilesField = ({
  key,
  originalFiles,
  newFiles,
  isNewFormat,
}: {
  key: string
  originalFiles: Array<FilesType>
  newFiles: Array<FilesType>
  isNewFormat?: boolean
}): AnyMxRecord => {
  const changedFiles = {
    ...parseArrayOfChildrenField(key, originalFiles, newFiles, (oldFile: any, newFile: any) => {
      return !oldFile ||
        Object.keys(oldFile).some(
          (oldFileKey) => !isEquals(oldFile[oldFileKey], newFile[oldFileKey])
        )
        ? {
            id: newFile.id,
            // @ts-ignore // TODO
            ...parseGenericField('name', getByPathWithDefault(null, 'name', oldFile), newFile.name),
            // @ts-ignore // TODO
            ...parseEnumField('type', getByPathWithDefault(null, 'type', oldFile), newFile.type),
            // @ts-ignore // TODO
            ...parseMemoField('memo', getByPathWithDefault(null, 'memo', oldFile), newFile.memo),
            // @ts-ignore // TODO
            ...parseTagsField('tags', getByPathWithDefault([], 'tags', oldFile), newFile.tags),
          }
        : []
    }),
  } as any

  if (!isNewFormat || !changedFiles[key]) {
    return changedFiles
  }

  const allFilesById = {
    ...(originalFiles ?? []).reduce((arr, file) => {
      // eslint-disable-next-line no-param-reassign
      arr[file.id] = file
      return arr
    }, {}),
    ...newFiles.reduce((arr, file) => {
      // eslint-disable-next-line no-param-reassign
      arr[file.id] = file
      return arr
    }, {}),
  }

  const deletedFiles = findDeletedArrayData('id', originalFiles, newFiles)

  const newFormattedFiles = [
    ...changedFiles[key].map((file) => {
      // eslint-disable-next-line no-param-reassign
      file.type = allFilesById[file.id].type
      return file
    }),
    ...deletedFiles.map((file) => ({
      // @ts-ignore // TODO
      id: file.id,
      // @ts-ignore // TODO
      type: allFilesById[file.id].type,
      orphan: true,
    })),
  ]

  return {
    [key]: newFormattedFiles,
  } as any
}

type ApprovalType = {
  approvedBy: {
    id: string
  }
  approvedAt: string
}

// Use for Approval fields. Need to send only approvedBy, not approvedAt.
export const parseApprovalField = (
  key: string,
  originalApproval: null | ApprovalType,
  newApproval: null | ApprovalType
): { [k: string]: any } => {
  const originalApprovedById = getByPathWithDefault(null, 'approvedBy.id', originalApproval)
  const newApprovedById = getByPathWithDefault(null, 'approvedBy.id', newApproval)

  const originalApprovedAt =
    (originalApproval && originalApproval.approvedAt && new Date(originalApproval.approvedAt)) ||
    null
  const newApprovedAt =
    (newApproval && newApproval.approvedAt && new Date(newApproval.approvedAt)) || null

  const parsedOriginalApproval = {
    approvedById: originalApprovedById,
    approvedAt: originalApprovedAt,
  }
  const parsedNewApproval = {
    approvedById: newApprovedById,
    approvedAt: newApprovedAt,
  }

  if (!isEquals(parsedOriginalApproval, parsedNewApproval)) return { [key]: newApprovedById }
  return {}
}

// Use for Representative Batch. Send index, not id.
export const parseDefaultIndexField = (
  key: string,
  originalValues: null | {
    id: string
  },
  newValues: null | {
    id: string
  },
  sources: Array<{ [k: string]: any }>
): { [k: string]: any } => {
  const originalValuesId = getByPathWithDefault(null, 'id', originalValues)
  const newValuesId = getByPathWithDefault(null, 'id', newValues)

  if (isEquals(originalValuesId, newValuesId)) return {}

  let newValuesIndex = sources.findIndex((item) => item.id === newValuesId)
  // @ts-ignore // TODO
  if (newValuesIndex === -1) newValuesIndex = null

  return { [key]: newValuesIndex }
}

// For Size fields (length, width, height). Needs to handle case when null, need to inject default values for all.
export const parseSizeField = (
  key: string,
  originalSize: {
    height?: MetricValue
    width?: MetricValue
    length?: MetricValue
  } | void,
  newSize: { height?: MetricValue; width?: MetricValue; length?: MetricValue }
): any => {
  if (isEquals(originalSize, newSize)) return {}

  return {
    [key]: {
      height: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'height', newSize),
      width: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'width', newSize),
      length: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'length', newSize),
    },
  }
}

/**
 * compares if two arrays are equal
 * @param isSameOrder Set to true if order position matters
 * note: upgrade this if object arrays are to be compared
 */
export const areArraysEqual = ({
  arr1,
  arr2,
  isSameOrder,
}: {
  arr1: any[]
  arr2: any[]
  key?: string
  isSameOrder?: boolean
}) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false
  }

  // .concat() to not mutate arguments
  // eslint-disable-next-line
  const _arr1 = arr1.concat()
  // eslint-disable-next-line
  const _arr2 = arr2.concat()

  if (!isSameOrder) {
    _arr1.sort()
    _arr2.sort()
  }

  for (let i = 0; i < _arr1.length; i++) {
    if (_arr1[i] !== _arr2[i]) {
      return false
    }
  }

  return true
}
