// @flow
import { useApolloClient, useLazyQuery, useQuery, useSubscription } from '@apollo/client'
import * as React from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import draftMessageQuery from '@graphql/client/chat/query.draftMessage.graphql'
import emojisQuery from '@graphql/client/chat/query.emojis.graphql'
import messagesQuery from '@graphql/client/chat/query.messages.graphql'
import messageNewSubscription from '@graphql/client/chat/subscription.messageNew.graphql'
import type {
  DraftMessageQuery,
  DraftMessageQueryVariables,
  EmojiFragment,
  MessageNewSubscription,
  MessagesQuery,
  MessagesQueryVariables,
  SubscriptionMessageNewArgs,
  MinimalOrganizationFragment,
  UserCardFragment,
  Scalars,
  EmojisQuery,
  EmojisQueryVariables,
} from '@graphql/server/flow'

import { getEntityInput } from '../helpers'
import type { ChatEntity } from '../type'

import { chatReducer, initialReducerState } from './reducer'
import type { ChatReducerState } from './reducer'

type ChatContextState = {
  state: ChatReducerState,
  dispatch: ($Call<<S, A>((S, A) => S) => A, typeof chatReducer>) => void,
  entityId: string,
  entityType: ChatEntity,
  readMessageCache: () => void,
  emojis: EmojiFragment[],
  selectedGroupId: Scalars['ID'],
  relatedPartners: MinimalOrganizationFragment[],
  mentionUsers: UserCardFragment[],
  isLoadingMessages: boolean,
  onLoadMore: Function,
  page: number,
  totalPage: number,
}

const initialContextState: ChatContextState = {
  state: initialReducerState,
  entityId: '',
  entityType: 'Shipment',
  readMessageCache() {},
  emojis: [],
  selectedGroupId: '',
  relatedPartners: [],
  mentionUsers: [],
  isLoadingMessages: false,
  dispatch: () => {},
  onLoadMore: () => {},
  page: 1,
  totalPage: 1,
}

const ChatContext = createContext<ChatContextState>(initialContextState)

export const useChat = (): ChatContextState => useContext(ChatContext)

const ROW_HEIGHT = 40

type Props = {
  children?: React.Node,
  entityId: string,
  entityType: ChatEntity,
  selectedGroupId: string,
  relatedPartners: MinimalOrganizationFragment[],
  mentionUsers: UserCardFragment[],
}

export const ChatContextProvider = ({
  children,
  entityId,
  entityType,
  selectedGroupId,
  relatedPartners,
  mentionUsers,
}: Props): React.Node => {
  const client = useApolloClient()
  const [perPage] = useState<number>(Math.ceil(window.innerHeight / ROW_HEIGHT))

  const filterBy = useMemo(
    () => ({
      entityId,
      entityType: entityType.toLowerCase(), // Not sure if this is right, schema only designates string
      ...(!!selectedGroupId && { messageGroupId: selectedGroupId }),
    }),
    [entityType, entityId, selectedGroupId]
  )

  const [state, dispatch] = useReducer(chatReducer, {
    ...initialReducerState,
  })

  const { data: emojiData } = useQuery<EmojisQuery, EmojisQueryVariables>(emojisQuery)
  const emojis = (emojiData?.emojis ?? []).flatMap((emoji) =>
    emoji.__typename === 'Emoji' ? emoji : []
  )

  const [getMessages, { data, loading }] = useLazyQuery<MessagesQuery, MessagesQueryVariables>(
    messagesQuery,
    {
      onCompleted: ({ messages }: MessagesQuery) => {
        const newMessages = [
          ...messages.nodes.flatMap((m) => (m.__typename === 'Message' ? m : [])),
        ]
        newMessages.reverse()

        dispatch({
          type: 'SET_MESSAGES',
          payload: {
            messages: newMessages,
          },
        })
      },
    }
  )

  const [getDraftMessage] = useLazyQuery<DraftMessageQuery, DraftMessageQueryVariables>(
    draftMessageQuery,
    {
      onCompleted: ({ draftMessage }: DraftMessageQuery) => {
        dispatch({
          type: 'SET_MESSAGE_CONTENT',
          payload: {
            content: draftMessage.content,
          },
        })
        dispatch({
          type: 'SET_DRAFT_MESSAGE',
          payload: {
            draftMessage: draftMessage.content,
          },
        })
      },
    }
  )

  const page = data?.messages.page ?? 1
  const totalPage = data?.messages.totalPage ?? 1

  const onLoadMore = useCallback(() => {
    getMessages({
      variables: {
        page: page + 1,
        perPage,
        filterBy,
        sortBy: {
          createdAt: 'DESCENDING',
        },
      },
    })
  }, [page, perPage, getMessages, filterBy])

  const entity = useMemo(() => getEntityInput({ entityId, entityType }), [entityId, entityType])

  useSubscription<MessageNewSubscription, SubscriptionMessageNewArgs>(messageNewSubscription, {
    variables: {
      entity,
      messageGroupId: selectedGroupId,
    },
    onSubscriptionData: ({ subscriptionData: { data: newMessageData } }) => {
      if (newMessageData?.messageNew) {
        const { messageNew } = newMessageData
        dispatch({
          type: 'SET_MESSAGES',
          payload: {
            setMessages: (oldMessages) => [...oldMessages, messageNew],
          },
        })
        client.cache.updateQuery<MessagesQuery, MessagesQueryVariables>(
          {
            query: messagesQuery,
            variables: {
              page,
              perPage,
              filterBy,
              sortBy: {
                createdAt: 'DESCENDING',
              },
            },
          },
          (existingData) => {
            if (!existingData) return undefined
            return {
              ...existingData,
              messages: {
                ...existingData.messages,
                // we put the new item at the start because we are sorting by newest first in the variables above
                nodes: [messageNew, ...existingData.messages.nodes],
              },
            }
          }
        )
      }
    },
  })

  useEffect(() => {
    dispatch({
      type: 'RESET_MODE',
      payload: {},
    })

    dispatch({
      type: 'SET_DRAFT_MESSAGE',
      payload: { draftMessage: null },
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: {
        setMessages: () => {
          return []
        },
      },
    })

    getDraftMessage({
      variables: {
        input: { entity, messageGroupId: selectedGroupId },
      },
    })

    getMessages({
      variables: {
        page: 1,
        perPage,
        filterBy,
        sortBy: {
          createdAt: 'DESCENDING',
        },
      },
    })
  }, [entity, selectedGroupId, filterBy, getDraftMessage, getMessages, perPage])

  return (
    <ChatContext.Provider
      value={{
        state,
        dispatch,
        entityId,
        entityType,
        readMessageCache() {
          // merged queries are always stored with their most up-to-date variables, meaning that reading cache with latest variables should return the full list
          getMessages({
            variables: {
              page,
              perPage,
              filterBy,
              sortBy: {
                createdAt: 'DESCENDING',
              },
            },
            fetchPolicy: 'cache-only',
          })
        },
        emojis,
        selectedGroupId,
        relatedPartners,
        mentionUsers,
        isLoadingMessages: loading,
        onLoadMore,
        page,
        totalPage,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
